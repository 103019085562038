//------------------------------------------------------------------------------
import Utils from './Utils';
import { EventEmitter } from 'events';
import Entity from './Entity';
import SDK3DVerse_EntityRegistry, { ComponentDescription } from './EntityRegistry';
import SDK3DVerse_Streamer from './Streamer';
import { AABB, Client, ComponentMap, ComponentName, EditorEntity, Overridden, SettingDescription, SettingName, SettingsMap, Stats, Transform } from 'types';

//------------------------------------------------------------------------------
export default class SDK3DVerse_EditorAPI extends EventEmitter {
    notifier: EventEmitter;
    entityRegistry: SDK3DVerse_EntityRegistry;
    sceneSettings: SettingsMap;
    clientColors: Record<string, string>;
    canEdit: boolean;
    actionStack: unknown[];
    requestPromises: ((result: any, isError?: boolean) => void)[];
    actionPromises: ((result: any, isError?: boolean) => Promise<void>)[];
    sequence: null | { type: string, data: unknown }[];
    processingAction: boolean;
    onConnected: Promise<void>;
    onConnectedCallback: () => void;
    webSocketURL: string;
    clientUUID: string;
    sessionKey: string;
    socket: WebSocket;
    workspaceUUID: string;
    sceneUUID: string;
    userUUID: string;
    sessionUUID: string;
    componentClasses: Record<ComponentName, ComponentDescription>;
    settingDescriptions: Record<SettingName, SettingDescription>;
    clientRTID: string;
    selectColor: string;
    stats: unknown;
    isTransientSession: boolean;
    streamer: SDK3DVerse_Streamer;

    //--------------------------------------------------------------------------
    constructor(notifier: EventEmitter, entityRegistry: SDK3DVerse_EntityRegistry, streamer: SDK3DVerse_Streamer)
    {
        super();

        this.notifier           = notifier;
        this.entityRegistry     = entityRegistry;
        this.streamer           = streamer;

        this.sceneSettings      = {};
        this.clientColors       = {};
        this.canEdit = false;
        this.resetConnectedPromise();
    }

    //--------------------------------------------------------------------------
    listenMessages()
    {
        this.notifier.on('onEditorMessageReceived', this.onMessageReceived);
    }

    //--------------------------------------------------------------------------
    resetState()
    {
        this.actionStack        = [];
        this.requestPromises    = [];
        this.actionPromises     = [];
        this.sequence           = null;
        this.processingAction   = false;
    }

    //--------------------------------------------------------------------------
    resetConnectedPromise()
    {
        this.onConnected = new Promise((resolve, reject) =>
        {
            this.onConnectedCallback = resolve;
            // reject if streamer connection closes before editor connects
            this.notifier.on('onConnectionClosed', reject);
        });
    }

    //--------------------------------------------------------------------------
    // private
    close()
    {
        this.sendRequest('close');
    }

    //--------------------------------------------------------------------------
    // private
    onMessageReceived = (payload) =>
    {
        switch(payload.type)
        {
            case "connect-confirmation":
                this.handleConnectConfirmation(payload.data);
                break;

            case "retrieve-children":
            case "find-entities-with-components":
            case "resolve-ancestors":
            case "find-entities-by-names":
            case "get-entities-by-euid":
            case "filter-entities":
            case "export-entity-to-scene":
                this.handleRequestResponse(payload.data);
                break;

            case "next-undo-redo":
                this.handleNextUndoRedoEvent(payload.data);
                break;

            case "client-color":
                this.handleClientColor(payload.data);
                break;

            case "scene-stats-update":
                this.handleSceneStatsUpdate(payload.data);
                break;

            case "error":
                console.error(payload.data);
                this.emit('editor-error', payload.data);
                break;

            default:
            {
                this.actionStack.push(payload);
                if(this.processingAction == false)
                {
                    this.processAction();
                }
            }
            break;
        }

    }

    //--------------------------------------------------------------------------
    // private
    async processAction()
    {
        let action              = null;
        this.processingAction   = true;

        while(action = this.actionStack.pop())
        {
            try
            {
                await this.handleAction(action);
            }
            catch(error)
            {
                console.error(`Error processing action:`, action, error);
            }
        }

        this.processingAction = false;
    }

    //--------------------------------------------------------------------------
    // private
    async handleAction(payload)
    {
        if(payload.emitter)
        {
            this.patchClientColor(payload.emitter);
        }

        switch(payload.type)
        {
            // Entity creation event from another user.
            case "create-entity":
            case "create-entities":
            case "restore-entities":
            case "spawn-entity":
                await this.handleEntitiesCreatedEvent(payload.data, payload.emitter);
                break;

            // Create entity request response
            case "entities-created":
            case "entity-reparented":
            case "entities-deleted":
            case "animation-sequence-instance-added":
            case "animation-sequence-instance-updated":
            case "animation-sequence-instance-removed":
            case "node-visibility-changed":
                await this.handleActionResponse(payload.data);
                break;

            case "action-error":
                await this.handleActionError(payload.data);
                break;

            case "attach-components":
            case "update-components":
                await this.handleUpdateComponentsEvent(payload.data, payload.emitter);
                break;

            case "detach-components":
                this.handleDetachComponentsEvent(payload.data, payload.emitter);
                break;

            case "delete-entities":
                this.handleEntitiesDeletedEvent(payload.data, payload.emitter);
                break;

            case "delete-entities-with-rtid":
                this.handleEntitiesDeletedWithRtidEvent(payload.data, payload.emitter);
                break;

            case "reparent-entity":
                await this.handleReparentEntityEvent(
                    payload.data.movingEntityEUID,
                    payload.data.oldParentEUID,
                    payload.data.newParentEUID,
                    payload.data.updatedAncestors,
                    payload.emitter
                );
                break;

            case "entities-overridden":
                this.handleEntitiesOverriddenEvent(payload.data);
                break;

            case "update-settings":
                this.handleUpdateSettingsEvent(payload.data);
                break;

            case "select-entities":
                this.handleSelectEntitiesEvent(payload.data, payload.emitter);
                break;

            case "set-node-visibility":
                await this.handleEntityVisibilityChangedEvent(payload.data, payload.emitter);
                break;
        }

        this.emit('debug', payload);
    }

    //--------------------------------------------------------------------------
    // private
    async sendCreateEntityRequest(requestType: string, payload)
    {
        return await this.sendActionAsync(
            requestType,
            payload,
            async (entityCreated) =>
            {
                const emitter = null;
                return await this.handleEntitiesCreatedEvent(entityCreated, emitter);
            }
        );
    }

    //--------------------------------------------------------------------------
    // private
    async deleteEntities(entityEUIDs: string[])
    {
        return await this.sendActionAsync(
            'delete-entities',
            entityEUIDs,
            async (deletedEntityEUIDs) =>
            {
                const emitter = null;
                return await this.handleEntitiesDeletedEvent(deletedEntityEUIDs, emitter);
            }
        );
    }

    //--------------------------------------------------------------------------
    // private
    updateEntities(entitiesToUpdate: Array<Entity>) {
        const attachRequest = {};
        const updateRequest = {};
        const detachRequest = {};

        for(const entity of entitiesToUpdate)
        {
            const euid = entity.getEUID();

            entity.dirtyComponents.length = 0;
            this.fillRequest(attachRequest, euid, entity, entity.attachedComponents, true);
            this.fillRequest(updateRequest, euid, entity, entity.unsavedComponents, true);
            this.fillRequest(detachRequest, euid, entity, entity.detachedComponents, false);
        }

        if(Object.keys(attachRequest).length > 0)
        {
            this.sendRequest('attach-components', attachRequest);
        }
        if(Object.keys(updateRequest).length > 0)
        {
            this.sendRequest('update-components', updateRequest);
        }
        if(Object.keys(detachRequest).length > 0)
        {
            this.sendRequest('detach-components', detachRequest);
        }
    }

    //--------------------------------------------------------------------------
    // private
    fillRequest(request: Record<string, ComponentMap | ComponentName[]>, euid: string, entity: Entity, srcComponents: ComponentName[], addComponentValue: boolean)
    {
        for(const i in srcComponents)
        {
            if(!request.hasOwnProperty(euid))
            {
                request[euid] = addComponentValue ? {} as ComponentMap: [];
            }

            const componentName = srcComponents[i];

            if(addComponentValue)
            {
                request[euid][componentName] = entity.getComponent(componentName);
            }
            else
            {
                (request[euid] as ComponentName[]).push(componentName);
            }
        }

        srcComponents.length = 0;
    }

    //--------------------------------------------------------------------------
    // private
    async reparentEntity(movingEntityEUID: string, oldParentEUID: string, newParentEUID: string, commit = true)
    {
        await this.sendActionAsync('reparent-entity',
            {
                movingEntityEUID, oldParentEUID, newParentEUID, commit
            },
            async (result) =>
            {
                const emitter = null;
                await this.handleReparentEntityEvent(
                    result.movingEntityEUID,
                    result.oldParentEUID,
                    result.newParentEUID,
                    result.updatedAncestors,
                    emitter
                );
            }
        );
    }

    //--------------------------------------------------------------------------
    // private
    setEntityVisibility(entityRTID: string, isVisible: boolean, handler)
    {
        return this.sendActionAsync(
            'set-node-visibility',
            {
                entityRTID : entityRTID,
                isVisible : isVisible
            },
            async (entityVisibilityChangedData) =>
            {
                const emitter = null;
                return handler(entityVisibilityChangedData, emitter);
            }
        );
    }

    //--------------------------------------------------------------------------
    // private
    async findEntitiesByNames(entityNames: string[])
    {
        const editorEntities = await this.sendRequestAsync('find-entities-by-names', entityNames) as EditorEntity[];
        for(const editorEntity of editorEntities)
        {
            if(editorEntity != null)
            {
                await this.resolveAncestors(editorEntity.rtid);
            }
        }
        return await this.formatEditorEntities(editorEntities);
    }

    //--------------------------------------------------------------------------
    // private
    async resolveEntitiesByEUID(entityEUID: string)
    {
        const editorEntities = await this.sendRequestAsync('get-entities-by-euid', entityEUID) as EditorEntity[];
        for(const editorEntity of editorEntities)
        {
            await this.resolveAncestors(editorEntity.rtid);
        }
        return await this.formatEditorEntities(editorEntities);
    }

    //--------------------------------------------------------------------------
    // private
    async getChildren(parentNode: Entity)
    {
        const children = await this.sendRequestAsync('retrieve-children', parentNode.getID()) as EditorEntity[];
        return await this.formatEditorEntities(children);
    }

    //--------------------------------------------------------------------------
    // private
    async formatEditorEntities(editorEntities: EditorEntity[])
    {
        const entities: Entity[] = [];

        for(const i in editorEntities)
        {
            if(editorEntities[i] != null)
            {
                this.patchEditorEntity(editorEntities[i]);

                const entity = await this.entityRegistry.getOrAddEntity(editorEntities[i].rtid, editorEntities[i]);
                entities.push(entity);
            }
            else
            {
                entities.push(null);
            }
        }

        return entities;
    }

    //--------------------------------------------------------------------------
    // private
    async resolveAncestors(entityRTID: string)
    {
        const ancestors = await this.sendRequestAsync('resolve-ancestors', entityRTID) as EditorEntity[];
        return Promise.all(ancestors.map(async ancestor =>
        {
            const existingEntity = this.entityRegistry.getEntity(ancestor.rtid);

            if(existingEntity)
            {
                return existingEntity;
            }

            this.patchEditorEntity(ancestor);

            const entity = await this.entityRegistry.addEntity(ancestor);
            this.notifier.emit("entityResolved", entity);

            return entity;
        }));
    }

    //--------------------------------------------------------------------------
    // private
    updateAABB(sceneUUID: string, aabb: AABB)
    {
        this.sendRequest('update-aabb', {sceneUUID, aabb});
    }

    //--------------------------------------------------------------------------
    // private
    exportEntityToScene(entityRTID: string, sceneName: string, workspaceUUID: string, rootNodeTransform: Transform)
    {
        return this.sendRequestAsync('export-entity-to-scene', {entityRTID, sceneName, workspaceUUID, rootNodeTransform}) as Promise<{ createdSceneUUID: string; error?: Error }>;
    }

    //--------------------------------------------------------------------------
    // private
    sendEntitySelectCommand(selectedEntities: Entity[], unselectedEntities: Entity[])
    {
        this.sendRequest(
            'select-entities',
            {
                selectedEntityRTIDs     : selectedEntities.map(e => e.getID()),
                unselectedEntityRTIDs   : unselectedEntities.map(e => e.getID())
            }
        );
    }

    //--------------------------------------------------------------------------
    // private
    async handleConnectConfirmation(data: {
        workspaceUUID: string;
        sceneUUID: string;
        userUUID: string;
        components: Record<ComponentName, ComponentDescription>;
        sessionUUID: string;
        settings: SettingsMap;
        settingDescriptions: Record<SettingName, SettingDescription>
        sceneSettings: SettingsMap;
        clientRTID: string;
        canEdit: boolean;
        clientColors: Record<string, string>;
        selectColor: string;
        stats: Stats;
        undoRedo: unknown;
        rootNodes: EditorEntity[];
        isTransient: boolean;
    })
    {
        this.resetState();

        this.workspaceUUID              = data.workspaceUUID;
        this.sceneUUID                  = data.sceneUUID;
        this.userUUID                   = data.userUUID;
        this.sessionUUID                = data.sessionUUID;
        this.componentClasses           = data.components;
        this.settingDescriptions        = data.settingDescriptions;
        this.sceneSettings              = data.settings;
        this.clientRTID                 = data.clientRTID;
        this.canEdit                    = data.canEdit;
        this.clientColors               = data.clientColors;
        this.selectColor                = data.selectColor;
        this.stats                      = data.stats;
        this.isTransientSession         = data.isTransient;

        this.emit('editor-connected');

        const emitter = null;
        const rootEntities = await this.handleEntitiesCreatedEvent(data.rootNodes, emitter, false);
        this.notifier.emit('sceneGraphReady', rootEntities);

        this.handleNextUndoRedoEvent(data.undoRedo);
        this.onConnectedCallback();
    }

    //--------------------------------------------------------------------------
    patchEditorEntity(entity: EditorEntity)
    {
        for(const client of entity.selectingClients)
        {
            this.patchClientColor(client);
        }

        for(const descendantRTID in entity.selectedDescendants)
        {
            const clients = entity.selectedDescendants[descendantRTID];
            for(const client of clients)
            {
                this.patchClientColor(client);
            }
        }
    }

    //--------------------------------------------------------------------------
    patchClientColor(client: Partial<Client>)
    {
        if(this.clientColors.hasOwnProperty(client.clientUUID))
        {
            client.color = this.clientColors[client.clientUUID];
        }
        else
        {
            client.isExternal = true;
        }
    }

    //--------------------------------------------------------------------------
    // private
    async handleEntitiesCreatedEvent(editorEntities: EditorEntity[], emitter: string, emitEvent = true)
    {
        const createdEntities = [];
        for(const i in editorEntities)
        {
            const editorEntity = editorEntities[i];
            this.patchEditorEntity(editorEntity);

            const createdEntity = await this.entityRegistry.addEntity(editorEntity);

            const parent = createdEntity.getParent();
            if(parent)
            {
                // If parent has been resolved just before, the child is already registered in this children
                const index = parent.children.indexOf(createdEntity.getID());
                if(index === -1)
                {
                    parent.children.push(createdEntity.getID());
                }
            }

            createdEntities.push(createdEntity);

            if(emitEvent)
            {
                this.emit('entity-created', createdEntity, emitter);
            }
        }

        return createdEntities;
    }

    //--------------------------------------------------------------------------
    // private
    handleEntitiesDeletedEvent(deletedEntityEUIDs: string[], emitter: string)
    {
        const deletedEntityRTIDs = [];
        for(const deletedEntityEUID of deletedEntityEUIDs)
        {
            const entities = this.entityRegistry.getEntitiesByEUID(deletedEntityEUID);
            for(const entity of entities)
            {
                this.entityRegistry.deleteEntity(entity, deletedEntityRTIDs);
            }
        }

        this.emit('entities-deleted', deletedEntityRTIDs, emitter);
    }

    //--------------------------------------------------------------------------
    // private
    handleEntitiesDeletedWithRtidEvent(deletedEntityRTIDs: string[], emitter: string)
    {
        for(const deletedEntityRTID of deletedEntityRTIDs)
        {
            const entity = this.entityRegistry.getEntity(deletedEntityRTID);
            if(entity)
            {
                this.entityRegistry.deleteEntity(entity);
            }
        }

        this.emit('entities-deleted', deletedEntityRTIDs, emitter);
    }

    //--------------------------------------------------------------------------
    // private
    async handleUpdateComponentsEvent(entitiesToUpdate: Record<string, {
        updatedComponents: ComponentName[],
        updatedAncestors: string[];
        deletedChildren: string | null;
        addedChildren: string | null;
    }>, emitter: string)
    {
        const updatedEntities           = [];
        const updatedAncestorSet        = new Set<Entity>();
        const updatedComponentByEUIDs   = {};
        const deletedEntityRTIDs        = [];
        const createdEntities           = [];

        for(const euid in entitiesToUpdate)
        {
            const {
                updatedComponents,
                updatedAncestors,
                deletedChildren = null,
                addedChildren = null
            } = entitiesToUpdate[euid];
            const entities = this.entityRegistry.getEntitiesByEUID(euid);

            updatedComponentByEUIDs[euid] = Object.keys(updatedComponents);

            for(const entity of entities)
            {
                let updateComponentList = false;
                for(const componentName in updatedComponents)
                {
                    updateComponentList = updateComponentList || !entity.isAttached(componentName as ComponentName);
                    entity.UNSAFE_setComponent(componentName as ComponentName, updatedComponents[componentName]);
                }
                updatedEntities.push(entity);

                if(updateComponentList)
                {
                    entity.updateComponentList();
                }

                if(deletedChildren && deletedChildren.hasOwnProperty(entity.getID()))
                {
                    const deletedChildrenRTIDs = deletedChildren[entity.getID()];

                    for(const rtid of deletedChildrenRTIDs)
                    {
                        const deletedEntity = this.entityRegistry.getEntity(rtid);
                        if(deletedEntity)
                        {
                            this.entityRegistry.deleteEntity(deletedEntity, deletedEntityRTIDs);
                        }
                    }
                }

                if(addedChildren && addedChildren.hasOwnProperty(entity.getID()))
                {
                    const newChildren = addedChildren[entity.getID()];
                    for(const child of newChildren)
                    {
                        createdEntities.push(child);
                    }
                }
            }

            for(const euid of updatedAncestors)
            {
                const ancestors = this.entityRegistry.getEntitiesByEUID(euid);

                for(const ancestor of ancestors)
                {
                    updatedAncestorSet.add(ancestor);
                }
            }
        }

        this.emit('entities-updated', updatedEntities, updatedComponentByEUIDs, emitter, Array.from(updatedAncestorSet));

        if(deletedEntityRTIDs.length > 0)
        {
            this.emit('entities-deleted', deletedEntityRTIDs, emitter);
        }

        if(createdEntities.length > 0)
        {
            await this.handleEntitiesCreatedEvent(createdEntities, emitter);
        }
    }

    //--------------------------------------------------------------------------
    // private
    handleDetachComponentsEvent(componentsToDetachPerEntity: ComponentName[], emitter: string)
    {
        const updatedEntities = [];
        const entitiesToDelete = [];

        for(const euid in componentsToDetachPerEntity)
        {
            const entities = this.entityRegistry.getEntitiesByEUID(euid);
            const componentsToDetach = componentsToDetachPerEntity[euid];

            for(const entity of entities)
            {
                for(const componentName of componentsToDetach)
                {
                    entity.UNSAFE_detachComponent(componentName as ComponentName);
                }

                if(componentsToDetach.includes('scene_ref'))
                {
                    // When detaching scene_ref, delete all entities referecing this entity as its linker
                    const childrenToDelete  = entity.children.map(rtid => this.entityRegistry.getEntity(rtid))
                                                .filter(e => e && e.linker === entity);

                    entitiesToDelete.splice(childrenToDelete.length, 0, ...childrenToDelete);
                    entity.UNSAFE_detachComponent('stats');
                }

                updatedEntities.push(entity);
                entity.updateComponentList();
            }
        }

        this.emit('entities-updated', updatedEntities, componentsToDetachPerEntity, emitter);

        if(entitiesToDelete.length > 0)
        {
            const deletedEntityRTIDs = [];

            for(const entity of entitiesToDelete)
            {
                this.entityRegistry.deleteEntity(entity, deletedEntityRTIDs);
            }
            this.emit('entities-deleted', deletedEntityRTIDs, emitter);
        }
    }

    //--------------------------------------------------------------------------
    // private
    async handleReparentEntityEvent(movingEntityEUID: string, oldParentEUID: string, newParentEUID: string, updatedAncestors: Set<Entity>, emitter: string)
    {
        const updatedAncestorSet = new Set<Entity>();
        for(const movingRTID in updatedAncestors)
        {
            const { newAncestors, oldAncestors, selectingClients } = updatedAncestors[movingRTID];

            for(const client of selectingClients)
            {
                this.patchClientColor(client);
            }

            this.unselectEntityFromAncestors(movingRTID, oldAncestors, updatedAncestorSet);
            this.selectEntityInAncestor(movingRTID, newAncestors, updatedAncestorSet, selectingClients);
        }

        if(updatedAncestorSet.size > 0)
        {
            this.notifier.emit('onClientEntitySelectionChanged',
                {
                selectedEntities    : [],
                unselectedEntities  : [],
                updatedAncestors    : Array.from(updatedAncestorSet),
                emitter
            });
        }

        const movingEntities        = await this.resolveEntitiesByEUID(movingEntityEUID);
        const oldParentCandidates   = this.entityRegistry.getEntitiesByEUID(oldParentEUID);
        const newParentCandidates   = this.entityRegistry.getEntitiesByEUID(newParentEUID);

        for(const movingEntity of movingEntities)
        {
            const rootNode          = movingEntity.isExternal() ? movingEntity.linker : null;
            const oldParentEntity   = oldParentEUID ? this.findParent(movingEntity, oldParentCandidates) : rootNode;
            const newParentEntity   = newParentEUID ? this.findParent(movingEntity, newParentCandidates) : rootNode;

            movingEntity.components.lineage.parentUUID = newParentEUID ? newParentEUID : Utils.invalidUUID;

            if(oldParentEntity)
            {
                const index = oldParentEntity.children.indexOf(movingEntity.getID());
                if(index !== -1)
                {
                    oldParentEntity.children.splice(index, 1);
                }
            }

            if(newParentEntity)
            {
                // If newParentEntity has been resolved just before, the child is already registered in this children
                const index = newParentEntity.children.indexOf(movingEntity.getID());
                if(index === -1)
                {
                    newParentEntity.children.push(movingEntity.getID());
                }
                movingEntity.components.lineage.ancestorRTID = newParentEntity.getID();
            }
            else if(newParentEntity === null) // root node
            {
                movingEntity.components.lineage.ancestorRTID = null;
            }
            else
            {
                console.warn(`The entity ${movingEntity.getName()} (${movingEntity.getID()}) has been moved into an unknown location.`);
            }

            this.emit('reparent-entity', movingEntity, oldParentEntity, newParentEntity, emitter);
        }
    }

    //--------------------------------------------------------------------------
    findParent(entity: Entity, candidates: Entity[])
    {
        if(!entity.isExternal())
        {
            if(candidates.length === 0)
            {
                return undefined; // Mysterious new parent
            }

            if(candidates.length > 1)
            {
                console.warn('Multiple candidates for internval entities, something is wrong');
            }

            return candidates[0];
        }

        if(!entity.isAttached('lineage'))
        {
            console.warn('External entity without lineage. Send help.');
            return null;
        }

        const entityLineage = entity.getComponent('lineage');

        return candidates.find(
            candidate =>
            {
                const candidateLineage = candidate.getComponent('lineage');

                return candidateLineage.value.every(
                    (linkerUUID, i) => linkerUUID === entityLineage.value[i]
                );
            }
        );
    }

    //--------------------------------------------------------------------------
    // private
    handleEntitiesOverriddenEvent(overriddenEntities: Record<string, { overriddenEntitiesRTIDs: string[]; overriddenComponent: Overridden }>)
    {
        for(const i in overriddenEntities)
        {
            const overriddenEntitiesRTIDs   = overriddenEntities[i].overriddenEntitiesRTIDs;
            const overriddenComponent       = overriddenEntities[i].overriddenComponent;

            const overrider = this.entityRegistry.getEntity(overriddenComponent.overriderEntityRTID);
            for(const j in overriddenEntitiesRTIDs)
            {
                const entity = this.entityRegistry.getEntity(overriddenEntitiesRTIDs[j]);
                if(entity)
                {
                    entity.UNSAFE_attachComponent('overridden', Utils.clone(overriddenComponent));
                    entity.overrider = overrider;
                    overrider.overriddenEntity = entity;
                    entity.updateComponentList();
                }
            }
        }

        this.emit('entities-overridden', overriddenEntities);
    }

    //--------------------------------------------------------------------------
    // private
    handleUpdateSettingsEvent(settings: SettingsMap)
    {
        for(const settingClass in settings)
        {
            this.sceneSettings[settingClass] = {
                ...this.sceneSettings[settingClass],
                ...settings[settingClass]
            };
        }

        this.emit('settings-updated', settings);
    }

    //--------------------------------------------------------------------------
    handleSelectEntitiesEvent(message: { selectedEntities: { rtid: string, ancestorRTIDs: string[] }[], unselectedEntities: { rtid: string, ancestorRTIDs: string[] }[] }, selectingClient: Client, triggerEventEmit = true)
    {
        const { selectedEntities, unselectedEntities } = message;
        const updatedAncestors = new Set<Entity>();

        const resolvedSelectedEntities  = selectedEntities
            .map(e => this.selectEntity(e, selectingClient, updatedAncestors))
            .filter(e => Boolean(e));

        const resolvedUnselectedEntities = unselectedEntities
            .map(e => this.unselectEntity(e, selectingClient, updatedAncestors))
            .filter(e => Boolean(e));

        if(triggerEventEmit && (selectedEntities.length > 0 || unselectedEntities.length > 0 || updatedAncestors.size > 0))
        {
            this.notifier.emit('onClientEntitySelectionChanged',
            {
                selectedEntities    : resolvedSelectedEntities,
                unselectedEntities  : resolvedUnselectedEntities,
                updatedAncestors    : Array.from(updatedAncestors),
                selectingClient
            });
        }
    }

    //--------------------------------------------------------------------------
    selectEntity({ rtid, ancestorRTIDs }: { rtid: string, ancestorRTIDs: string[] }, selectingClient: Client, updatedAncestors: Set<Entity>)
    {
        const entity = this.entityRegistry.getEntity(rtid);
        if(entity)
        {
            const alreadySelected = entity.selectingClients.some(c => c.clientUUID === selectingClient.clientUUID);
            if(!alreadySelected)
            {
                entity.selectingClients.push(selectingClient);
            }
            entity.updateSelectingClients();
        }

        for(const ancestorRTID of ancestorRTIDs)
        {
            const ancestor = this.getEntity(ancestorRTID);

            if(!ancestor)
            {
                continue;
            }

            if(!ancestor.selectedDescendants.hasOwnProperty(rtid))
            {
                ancestor.selectedDescendants[rtid] = [];
            }

            const alreadySelected = ancestor.selectedDescendants[rtid].some(c => c.clientUUID === selectingClient.clientUUID);
            if(alreadySelected)
            {
                continue;
            }

            updatedAncestors.add(ancestor);
            ancestor.selectedDescendants[rtid].push(selectingClient);
            ancestor.updateSelectingClients();
        }

        return entity;
    }

    //--------------------------------------------------------------------------
    unselectEntity({ rtid, ancestorRTIDs }: { rtid: string; ancestorRTIDs: string[] }, selectingClient: Client, updatedAncestors: Set<Entity>)
    {
        const entity = this.entityRegistry.getEntity(rtid);
        if(entity)
        {
            const index = entity.selectingClients.findIndex(c => c.clientUUID === selectingClient.clientUUID);
            if(index !== -1)
            {
                entity.selectingClients.splice(index, 1);
            }
            else
            {
                console.warn("Attempt to unselect an entity, and client not found", selectingClient)
            }

            entity.updateSelectingClients();
        }

        for(const ancestorRTID of ancestorRTIDs)
        {
            const ancestor = this.getEntity(ancestorRTID);

            if(!ancestor)
            {
                continue;
            }

            updatedAncestors.add(ancestor);

            if(!ancestor.selectedDescendants.hasOwnProperty(rtid))
            {
                console.warn(`Cannot find the entity ${rtid} while attempting to unselect from the ancestor ${ancestor.getID()}`)
                continue;
            }

            const clientIndex = ancestor.selectedDescendants[rtid].findIndex(c => c.clientUUID === selectingClient.clientUUID);
            if(clientIndex === -1)
            {
                console.warn(`Cannot find the client ${selectingClient.clientUUID} in entity ${rtid} while attempting to unselect from the ancestor ${ancestor.getID()}`)
                continue;
            }
            ancestor.selectedDescendants[rtid].splice(clientIndex, 1);
            ancestor.updateSelectingClients();
        }

        return entity;

    }

    //--------------------------------------------------------------------------
    unselectEntityFromAncestors(rtid: string, ancestorRTIDs: string[], updatedAncestors: Set<Entity>)
    {
        for(const ancestorRTID of ancestorRTIDs)
        {
            const ancestor = this.entityRegistry.getEntity(ancestorRTID);

            if(!ancestor)
            {
                continue;
            }

            updatedAncestors.add(ancestor);

            if(!ancestor.selectedDescendants.hasOwnProperty(rtid))
            {
                console.warn(`Cannot find the entity ${rtid} while attempting to unselect from the ancestor ${ancestor.getID()}`)
                continue;
            }

            delete ancestor.selectedDescendants[rtid];
            ancestor.updateSelectingClients();
        }
    }

    //--------------------------------------------------------------------------
    selectEntityInAncestor(rtid: string, ancestorRTIDs: string, updatedAncestors: Set<Entity>, clientList: Client[])
    {
        for(const ancestorRTID of ancestorRTIDs)
        {
            const ancestor = this.entityRegistry.getEntity(ancestorRTID);

            if(!ancestor)
            {
                continue;
            }

            updatedAncestors.add(ancestor);
            ancestor.selectedDescendants[rtid] = Array.from(clientList);
            ancestor.updateSelectingClients();
        }
    }

    //--------------------------------------------------------------------------
    // private
    handleClientColor(data: { clientUUID: string; color: string; })
    {
        this.clientColors[data.clientUUID] = data.color;
        this.emit('client-color', data);
    }

    //--------------------------------------------------------------------------
    handleSceneStatsUpdate(data)
    {
        this.stats = data;
        this.notifier.emit('onSceneStatsUpdated', this.stats);
    }

    //--------------------------------------------------------------------------
    async resolveEntityRef(entityRef: { originalEUID: string; linkage: string[] })
    {
        const { originalEUID, linkage = [] } = entityRef;

        let rtid = Utils.generateRTID(originalEUID);

        if(linkage.length > 0)
        {
            const linkerFlatRTIDs   = linkage.map(linkerEUID => Utils.generateRTID(linkerEUID));
            const linkerRTID        = linkerFlatRTIDs.reduce(
                (acc, linkerRTID) => Utils.generateRTID(linkerRTID, acc)
            );

            rtid = Utils.generateRTID(rtid, linkerRTID);
        }

        return await this.entityRegistry.resolveEntity(rtid.toString());
    }

    //--------------------------------------------------------------------------
    handleEntityVisibilityChangedEvent(data: { entityRTID: string, isVisible: boolean }, emitter: string)
    {
        this.emit('entity-visibility-updated', data, emitter);
    }

    //--------------------------------------------------------------------------
    // private
    handleNextUndoRedoEvent(data)
    {
        this.emit('next-undo-redo', data);
    }

    //--------------------------------------------------------------------------
    // private
    handleRequestResponse(data)
    {
        const callback = this.requestPromises.shift();
        callback(data);
    }

    //--------------------------------------------------------------------------
    // private
    async handleActionResponse(data)
    {
        const callback = this.actionPromises.shift();
        await callback(data);
    }

    //--------------------------------------------------------------------------
    async handleActionError(data)
    {
        const callback = this.actionPromises.shift();
        await callback(data, true);
    }

    //--------------------------------------------------------------------------
    // private
    prepareSequence()
    {
        if(this.sequence != null)
        {
            return false;
        }

        this.sequence = [];
        return true;
    }

    //--------------------------------------------------------------------------
    // private
    commitSequence(sequenceName: string)
    {
        const message = this.prepareMessage(
            'sequence',
            {
                name    : sequenceName,
                actions : this.sequence
            }
        );

        this.sequence = null;
        this.sendEditorMessage(JSON.stringify(message));
    }

    //--------------------------------------------------------------------------
    // private
    prepareMessage(type: string, data)
    {
        return {
            type : type,
            data : data
        };
    }

    //--------------------------------------------------------------------------
    // private
    sendRequest(type: string, data?: unknown)
    {
        const message = this.prepareMessage(type, data);

        if(this.sequence == null)
        {
            this.sendEditorMessage(JSON.stringify(message));
        }
        else
        {
            this.sequence.push(message);
        }
    }

    //--------------------------------------------------------------------------
    // private
    sendRequestAsync(type: string, data)
    {
        return new Promise( (resolve) =>
        {
            this.requestPromises.push((result) =>
            {
                console.log(`Response received for request ${type}.`);
                resolve(result);
            });

            this.sendRequest(type, data);
        });
    }

    //--------------------------------------------------------------------------
    // private
    sendActionAsync(type: string, data, handler)
    {
        return new Promise((resolve, reject) =>
        {
            this.actionPromises.push(async (result, isError = false) =>
            {
                if(isError)
                {
                    console.error(`Received an error for action ${type}.`, result);
                    reject(result.data);
                    return;
                }

                console.log(`Response received for action ${type}.`);
                const handlerResult = await handler(result);
                resolve(handlerResult);
            });

            this.sendRequest(type, data);
        });
    }

    //--------------------------------------------------------------------------
    getEntity(rtidOrObject: string | Entity)
    {
        if(typeof rtidOrObject === "string")
        {
            return this.entityRegistry.getEntity(rtidOrObject);
        }

        return rtidOrObject;
    }

    //---------------------------------------------------------------------------
    sendEditorMessage(payload: string)
    {
        this.streamer.sendEditorMessage(payload);
    }
}
