//------------------------------------------------------------------------------
export default {
    registration                : 1,
    video_stream                : 2,
    inputs                      : 3,
    viewer_control              : 4,
    client_remote_operations    : 5,
    editor_remote_operations    : 6,
    camera_sharing              : 7,
    broadcast_clients           : 8,
    broadcast_video_stream      : 9,
    connection_lifecycle        : 10,
    heartbeat                   : 11,
    video_stream_header         : 12,
    audio_stream                : 13,
    broadcast_script_events     : 14,
    asset_loading_events        : 15,
    gpu_memory_profiler         : 16,
    entities_simulation_updates : 17,
    editor_backend              : 18,
};
