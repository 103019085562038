//------------------------------------------------------------------------------
import SDK3DVerse_Decoder       from 'Decoder';
import SDK3DVerse_TaskStack     from 'TaskStack';
import SDK3DVerse_InputRelay    from 'InputRelay';
import SDK3DVerse_Utils         from 'Utils';
import AudioStreamer            from 'AudioStreamer'

//------------------------------------------------------------------------------
import ChannelIDs from 'ChannelIDs'
import { mat4 } from 'gl-matrix';

//------------------------------------------------------------------------------
const LITTLE_ENDIAN                 = true;
const FTL_HEADER_SIZE               = 4;
const RTID_SIZE                     = 4;
const UUID_SIZE                     = 16;

//------------------------------------------------------------------------------
const NO_SLICE                      = 0;
const SLICE_BEGIN                   = 1;
const SLICE_CONTINUE                = 2;
const SLICE_END                     = 3;

//------------------------------------------------------------------------------
const INACTIVITY_WARNING_TIMEOUT    = 5 * 60 * 1000;
const INACTIVITY_TIMEOUT            = 30 * 1000;

const HEARTBEAT_FREQUENCY           = 2 * 1000;

//------------------------------------------------------------------------------
const viewer_control_operation      =
{
    resize              : 0,
    ___unused_message   : 1,
    suspend             : 2,
    resume              : 3,
    encoder_params      : 4,
    set_viewports       : 5
};

//------------------------------------------------------------------------------
const connection_lifecycle_event_ids =
{
    _invalid__                              : 0,
    editor_connected                        : 1,
    editor_disconnected                     : 2,
    client_connected                        : 3,
    clients_disconnected                    : 4,
};

//------------------------------------------------------------------------------
export default class SDK3DVerse_Streamer
{
    //--------------------------------------------------------------------------
    constructor(notifier)
    {
        this.notifier                   = notifier;
        this.decoder                    = new SDK3DVerse_Decoder(this.notifier);
        this.taskStack                  = new SDK3DVerse_TaskStack();
        this.inputRelay                 = new SDK3DVerse_InputRelay(this.taskStack, notifier, this.purgeTasks);
        this.audioStreamer              = new AudioStreamer();
        this.inactivityWarningCallback  = null;
        this.areAssetsLoaded            = false;
        this.gpuProfileDataQueue        = [];
        this.registeredTasks            = new Map();
        this.slices                     = new Map();

        this.decoder.parseFrameData     = (frameData, imageSize) => this.parseFrameData(frameData, imageSize);

        this.resetConnectedState(false);
    }

    //--------------------------------------------------------------------------
    start(config, isWatchOnly)
    {
        this.config = config;
        if(!(this.config.watchOnly = isWatchOnly))
        {
            this.renderingAreaSize = this.config.resolution;
        }
        else
        {
            this.decoder.fitVideoInCanvas = true;
        }

        this.audioStreamer.createContext();

        if(this.canvas && !isWatchOnly)
        {
            this.inputRelay.initialize(this.canvas);
        }

        this.notifier.emit('onLoadingStarted');
        return this.connect().then(()=>
        {
            this.resetInactivityTimeout();
        });
    }

    //--------------------------------------------------------------------------
    stop()
    {
        this.taskStack.resetState();
        this.inputRelay.close();
        this.audioStreamer.releaseContext();
        if(this.socket)
        {
            this.socket.addEventListener('close', () => {
                this.resetConnectedState();
            }, { once: true });
            this.socket.close();
        } else {
            this.resetConnectedState();
        }

        if(this.inactivityWarningTimeout)
        {
            clearTimeout(this.inactivityWarningTimeout);
        }

        if(this.inactivityTimeout)
        {
            clearTimeout(this.inactivityTimeout);
        }
    }

    //--------------------------------------------------------------------------
    resetInactivityTimeout()
    {
        if(this.inactivityWarningTimeout)
        {
            clearTimeout(this.inactivityWarningTimeout);
        }

        if(!this.isConnected || this.config.watchOnly)
        {
            return;
        }

        this.inactivityWarningTimeout = setTimeout(
            () =>
            {
                this.inactivityWarningTimeout = null;
                if(!this.inactivityWarningCallback)
                {
                    console.warn('Inactivity timeout reached and no callback is set to notify the user. Please refer to setInactivityCallback https://docs.3dverse.com/sdk/SDK3DVerse.html#setInactivityCallback');
                    this.stop();
                    return;
                }

                this.inactivityTimeout = setTimeout(
                    () =>
                    {
                        console.warn('Inactivity timeout reached');
                        this.stop();
                        this.inactivityTimeout = null;
                    },
                    INACTIVITY_TIMEOUT
                );

                const cancelInactivityTimeout = () =>
                {
                    clearTimeout(this.inactivityTimeout);
                    this.inactivityTimeout = null;
                    this.resetInactivityTimeout();
                };

                this.inactivityWarningCallback(cancelInactivityTimeout);
            },
            INACTIVITY_WARNING_TIMEOUT
        );
    }

    //--------------------------------------------------------------------------
    resetConnectedState(testIfConnected)
    {
        if(testIfConnected && !this.isConnected)
        {
            return;
        }

        this.isConnected    = false;
        this.onConnected    = new Promise((resolve, reject) =>
        {
            this.onConnectedCallback = resolve;
            this.onConnectionFailedCallback = reject;
        });
    }

    //--------------------------------------------------------------------------
    setupDisplay(canvasElement)
    {
        this.canvas = canvasElement;
        if(this.config)
        {
            this.inputRelay.initialize(canvasElement);
        }
        this.decoder.setupDisplay(canvasElement);
        this.notifier.emit('onDisplayReady');
    }

    //--------------------------------------------------------------------------
    connect()
    {
        if(this.isConnected)
        {
            this.stop();
        }

        this.socket             = new WebSocket(this.getUrl());
        this.socket.binaryType  = 'arraybuffer';

        this.socket.onopen      = () => this.onSocketOpened();
        this.socket.onclose     = (event) => this.onSocketClosed(event);
        this.socket.onerror     = (event) => this.onSocketError(event);

        return this.onConnected;
    }

    //--------------------------------------------------------------------------
    getUrl()
    {
        if (this.config.connectionInfo.sslport)
        {
            return `wss://${this.config.connectionInfo.ip}:${this.config.connectionInfo.sslport}`;
        }
        else
        {
            return `ws://${this.config.connectionInfo.ip}:${this.config.connectionInfo.port}`;
        }
    }

    //--------------------------------------------------------------------------
    onSocketOpened()
    {
        console.log('Connected to '+ this.getUrl());

        this.notifier.emit(
            'onLoadingProgress',
            {
                status : 'connected',
                message : 'Socket connected'
            }
        );

        this.socket.onmessage =	(message) =>
        {
            var dataView            = new DataView(message.data);
            var statusCode          = this.config.connectionInfo.standalone ? 1 : dataView.getInt16(0, LITTLE_ENDIAN);
            var clientUUIDOffset    = this.config.connectionInfo.standalone ? 0 : 2;
            this.clientUUID         = this.parseUUID(message.data, clientUUIDOffset);
            this.taskStack.setClientUUID(this.clientUUID);

            if (statusCode != 1)
            {
                this.handleConnectionError(statusCode);
                return;
            }

            if(this.config.watchOnly)
            {
                this.setupClient(false);
                this.setupHeartbeat();
                this.notifyLoadingEnded();
                return;
            }

            this.sendViewerClientInfo();
        };

        if (this.config.connectionInfo.standalone)
        {
            // Don't need to send anything on standalone mode.
            return;
        }

        // single entry point
        const login = JSON.stringify(
        {
            sessionKey  : this.config.connectionInfo.sessionKey,
            clientApp   : `$browser:${navigator.userAgent}`,
            os          : navigator.platform
        });

        var connectionHeader = new ArrayBuffer(2);
        var bufferWriter     = new DataView(connectionHeader);

        bufferWriter.setInt8(0, 0xFF&(login.length), LITTLE_ENDIAN);
        bufferWriter.setInt8(1, 0xFF&(login.length>>8), LITTLE_ENDIAN);

        this.socket.send(connectionHeader);
        this.socket.send(login);
    }

    //--------------------------------------------------------------------------
    onSocketClosed(event)
    {
        if (!this.isConnected) {
            // connection closed before setup complete, so report an error
            this.handleConnectionError(102); // 102: Connection closed
        }

        console.log('Disconnected from ' + this.getUrl(), event);

        if(this.taskPurgeInterval)
        {
            clearInterval(this.taskPurgeInterval);
        }

        if(this.heartbeatTimeout)
        {
            clearTimeout(this.heartbeatTimeout);
            this.heartbeatTimeout = null;
        }

        if(this.inactivityTimeout)
        {
            clearTimeout(this.inactivityTimeout);
            this.inactivityTimeout = null;
        }

        if(this.inactivityWarningTimeout)
        {
            clearTimeout(this.inactivityWarningTimeout);
            this.inactivityWarningTimeout = null;
        }

        this.decoder.close();
        this.notifier.emit("onConnectionClosed");
        this.resetConnectedState();
    }

    //--------------------------------------------------------------------------
    onSocketError(event)
    {
        console.error('Connection Error:', event);
        this.notifier.emit("onStreamerSocketError", event);
        this.stop();
    }

    //--------------------------------------------------------------------------
    onMessageReceived(message)
    {
        const uInt8Data   = new Uint8Array(message.data);
        const dataView    = new DataView(message.data);
        const channelID   = uInt8Data[0];

        switch(channelID)
        {
            case ChannelIDs.video_stream:
            {
                const STREAM_CHANNEL_HEADER_SIZE        = 8;
                const STREAM_CHANNEL_HEADER_FULL_SIZE   = FTL_HEADER_SIZE + STREAM_CHANNEL_HEADER_SIZE;

                const imageSize     = dataView.getUint32(FTL_HEADER_SIZE, LITTLE_ENDIAN);
                const frameDataSize = dataView.getUint32(FTL_HEADER_SIZE + 4, LITTLE_ENDIAN);
                const frameData     = frameDataSize > 0 && !this.config.watchOnly
                                    ? message.data.slice(
                                        STREAM_CHANNEL_HEADER_FULL_SIZE + imageSize,
                                        STREAM_CHANNEL_HEADER_FULL_SIZE + imageSize + frameDataSize)
                                    : null;

                const encodedImage = new DataView(
                    dataView.buffer,
                    STREAM_CHANNEL_HEADER_FULL_SIZE,
                    imageSize
                );

                this.notifier.emit('onFrameReceived', encodedImage);
                this.decoder.onFrameReceived(encodedImage, frameData, imageSize);
            }
            break;

            case ChannelIDs.audio_stream:
                this.audioStreamer.onAudioReceived(FTL_HEADER_SIZE, LITTLE_ENDIAN, message, dataView);
                break;

            case ChannelIDs.viewer_control:
            {
                const newWidth  = dataView.getUint16(FTL_HEADER_SIZE, LITTLE_ENDIAN);
                const newHeight = dataView.getUint16(FTL_HEADER_SIZE + 2, LITTLE_ENDIAN);

                // update config in case resolution is updated before decoder has been initialized
                if(!this.decoder.isInitialized)
                {
                    this.config.resolution[0] = newWidth;
                    this.config.resolution[1] = newHeight;

                    this.config.display.canvasWidth     = this.canvasSize.width;
                    this.config.display.canvasHeight    = this.canvasSize.height;
                }
                else
                {
                    this.decoder.resize(newWidth, newHeight, this.canvasSize.width, this.canvasSize.height);
                }
                this.notifier.emit('onNewRenderingAreaSize', newWidth, newHeight);
            }
            break;

            case ChannelIDs.client_remote_operations:
            {
                const CLIENT_REMOTE_OPERATION_REPLY_HEADER_SIZE = 24;
                var taskResponseData = message.data.slice(FTL_HEADER_SIZE + CLIENT_REMOTE_OPERATION_REPLY_HEADER_SIZE);

                this.taskStack.onTaskResponse(taskResponseData);
            }
            break;

            case ChannelIDs.registration:
            {
                var codec   = uInt8Data[4];

                // retrieve codec type selected.
                if(this.config.display.hardwareDecoding && this.config.display.hevcSupport && codec !== 2)
                {
                    console.log("requested HEVC encoding, but receiving h264")
                    this.config.display.hevcSupport = false;
                }

                // now we are ready to initialize decoder
                this.decoder.initialize(this.config);
                if(this.config.connectionInfo.standalone)
                {
                    this.connectToRenderer();
                }
                else
                {
                    this.setupHeartbeat();
                    this.notifyLoadingEnded();
                }

                // We are now ready to receive frames (if visible)
                const isVisible = document.visibilityState != "hidden";
                this.setState(isVisible);

                // Set viewer as inactive when the tab have been hidden.
                document.addEventListener('visibilitychange', (e) =>
                {
                    const isVisible = document.visibilityState != "hidden";
                    this.setState(isVisible);
                });
            }
            break;

            case ChannelIDs.heartbeat:
                this.handleHeartbeatResponse();
                break;
            case ChannelIDs.broadcast_script_events:
                this.handleScriptEvents(message.data.slice(FTL_HEADER_SIZE));
                break;
            case ChannelIDs.asset_loading_events:
                this.handleAssetLoadingData(message.data.slice(FTL_HEADER_SIZE));
                break;
            case ChannelIDs.gpu_memory_profiler:
                this.handleGpuProfilerData(message.data.slice(FTL_HEADER_SIZE));
                break;
            case ChannelIDs.editor_backend:
                this.handleEditorMessage(message.data.slice(FTL_HEADER_SIZE));
                break;
            case ChannelIDs.connection_lifecycle:
                this.handleConnectionLifecycle(message.data.slice(FTL_HEADER_SIZE));
                break;

            default:
                console.warn("Received a message on an unsupported channel " + channelID);
        }
    }

    //--------------------------------------------------------------------------
    connectToRenderer()
    {
        const url = `ws://${this.config.connectionInfo.ip}:${this.config.connectionInfo.rendererPort}`;

        this.rendererSocket             = new WebSocket(url);
        this.rendererSocket.binaryType  = 'arraybuffer';

        this.rendererSocket.onopen      = () =>
        {
            console.log(`Connected to renderer on ${url}`);
            this.notifyLoadingEnded();
        };
        this.rendererSocket.onclose		= () =>
        {
            console.warn(`Disconnected from renderer on ${url}`);
        };
        this.rendererSocket.onerror		= (error) => this.onSocketError(error);
    }

    //--------------------------------------------------------------------------
    notifyLoadingEnded()
    {
        this.notifier.emit(
            'onLoadingEnded',
            {
                status : 'accepted',
                message : 'Connection accepted'
            }
        );

        this.isConnected = true;
        this.onConnectedCallback();
    }

    //--------------------------------------------------------------------------
    handleConnectionError(errorCode)
    {
        this.socket.onclose = function(){};	//Disable callback
        this.socket.close();

        let errorMessage;
        switch(errorCode)
        {
            case 100:
                errorMessage = "Authentication failed";
                break;
            case 101:
                errorMessage = "Session not found";
                break;
            case 102:
                errorMessage = "Session closed";
                break;

            case 200:
            case 201:
            case 202:
                errorMessage = "Rendering service unavailable";
                break;

            case 0:
            default:
                errorMessage = `Unknown Error code : ${errorCode}`;
                break;
        }

        this.notifier.emit(
            'onLoadingEnded',
            {
                status      : 'error',
                message     : errorMessage,
                errorCode   : errorCode
            }
        );

        // Reject the this.onConnected promise
        this.onConnectionFailedCallback(new Error(`Connection Error: ${errorMessage}`));

        // Need to emit manually because the onSocketClosed callback was disabled above.
        // NOTE: This will reset the streamer and the this.onConnected promise.
        this.notifier.emit('onConnectionClosed');
    }

    //--------------------------------------------------------------------------
    setupClient(purgeTasks = true)
    {
        this.socket.onmessage = (message) => this.onMessageReceived(message);

        if(purgeTasks)
        {
            this.startPurgeTasks();
        }
    }

    //--------------------------------------------------------------------------
    startPurgeTasks()
    {
        this.taskPurgeInterval = setInterval(
            this.purgeTasks,
            1000.0 / this.inputRelayFrequency
        );
    }

    //--------------------------------------------------------------------------
    registerTask = (taskID, task) =>
    {
        this.registeredTasks.set(taskID, task);
    }

    //--------------------------------------------------------------------------
    purgeTasks = () =>
    {
        for(const task of this.registeredTasks.values())
        {
            task();
        }
        this.registeredTasks.clear();

        while (!this.taskStack.isEmpty())
        {
            const { buffer, isRemoteOperation } = this.taskStack.pop();

            if(this.config.connectionInfo.standalone && isRemoteOperation)
            {
                this.rendererSocket.send(buffer);
            }
            else
            {
                this.socket.send(buffer);
            }

            this.resetInactivityTimeout();
        }
    }

    //--------------------------------------------------------------------------
    sendViewerClientInfo()
    {
        if(this.config.display.hardwareDecoding)
        {
            const encoderType   = this.config.display.hevcSupport
                                ? 'HEVC'
                                : 'H264';
            console.log(`Requesting ${encoderType} encoding.`);
        }

        const config = JSON.stringify(
        {
            renderingAreaSize   : this.renderingAreaSize,
            encoderConfig       :
            {
                codec       : this.config.display.hardwareDecoding && this.config.display.hevcSupport ? 2 : 0,
                profile     : this.config.display.hardwareDecoding && this.config.display.hevcSupport ? 1 : 0,
                frameRate   : 30,
                lossy       : true,
            },
            inputConfig :
            {
                hasKeyboard      : true,
                hasMouse         : true,
                hasHololens      : false,
                hasGamepad       : true,
                hasTouchscreen   : true
            }
        });

        this.sendConfig(config);
        this.setupClient();
    }

    //--------------------------------------------------------------------------
    sendConfig(config)
    {
        const headerBuffer  = new ArrayBuffer(FTL_HEADER_SIZE);
        const dataView      = new DataView(headerBuffer);

        writeMultiplexerHeader(dataView, ChannelIDs.registration, config.length);

        this.socket.send(headerBuffer);
        this.socket.send(config);
    }

    //--------------------------------------------------------------------------
    subscribeTo(clientUUID)
    {
        console.log('Subscribe to '+ clientUUID);
        const buffer        = new ArrayBuffer(FTL_HEADER_SIZE + UUID_SIZE);
        const dataView      = new DataView(buffer);
        const uuidBuffer    = SDK3DVerse_Utils.uuidToUint8Array(clientUUID);

        writeMultiplexerHeader(dataView, ChannelIDs.camera_sharing, UUID_SIZE);

        for(let i = 0; i < UUID_SIZE; i++)
        {
            dataView.setUint8(FTL_HEADER_SIZE + i, uuidBuffer[i]);
        }

        this.socket.send(buffer);
    }

    //--------------------------------------------------------------------------
    parseFrameData(data, imageSize)
    {
        // For retrocompatibility with older versions of the Engine
        // attempt two different offsets to parse the frame data
        try
        {
            // Hovered Entity + hovered position + hovered normal
            // not supported, but should be read to keep the offset correct
            const VEC3_BYTE_SIZE = 3 * 4;
            const clientMetadataOffset = RTID_SIZE + (VEC3_BYTE_SIZE * 2);
            return this._parseFrameData(data, imageSize, clientMetadataOffset);
        }
        catch(e)
        {
            try
            {
                return this._parseFrameData(data, imageSize, 0);
            }
            catch (e)
            {
                console.error('Failed to parse frame data', e);
                return null;
            }
        }
    }

    //--------------------------------------------------------------------------
    _parseFrameData(data, imageSize, clientMetadataOffset = 0)
    {
        let offset      = 0;
        const dataView  = new DataView(data);
        const frameData = {
            clientUUID          : this.clientUUID,
            clients             : {},
            networkTimestamp    : new Date(),
            rendererTimeStamp   : dataView.getUint32(0, true),
            frameCounter        : dataView.getUint32(4, true),
            imageSize           : imageSize
        };

        offset += 8;

        const clientCount     = dataView.getUint8(offset, true);
        offset++;

        const VIEWPORT_SIZE     = (16 * 4) + RTID_SIZE;
        const MAX_VIEWPORTS     = 8;

        for(let i = 0; i < clientCount; ++i)
        {
            const currentClientUUID = this.parseUUID(data, offset);
            offset += UUID_SIZE + clientMetadataOffset;

            const viewportCount     = dataView.getUint8(offset, true);
            offset++;

            const viewports         = [];

            for(let k = 0; k < viewportCount; ++k)
            {
                const cameraRTID  = SDK3DVerse_Utils.readRTID(dataView, offset).toString();
                offset += RTID_SIZE;

                const worldMatrixArray = [];
                for(let j = 0 ; j < 16; ++j)
                {
                    worldMatrixArray[j] = dataView.getFloat32(offset, true);
                    offset += 4;
                }

                viewports.push(
                {
                    cameraRTID : cameraRTID,
                    worldMatrix : mat4.fromValues(...worldMatrixArray)
                });
            }

            offset += (MAX_VIEWPORTS - viewportCount) * VIEWPORT_SIZE;

            frameData.clients[currentClientUUID] = {
                viewports : viewports
            };
        }

        return frameData;
    }

    //--------------------------------------------------------------------------
    parseUUID(data, offset)
    {
        // copy the data to a new buffer to avoid modifying the original
        const dataCopy = data.slice(offset, offset + UUID_SIZE);
        const dataView = new DataView(dataCopy, 0, UUID_SIZE);

        const data1 = dataView.getUint32(0, true);
        const data2 = dataView.getUint16(4, true);
        const data3 = dataView.getUint16(6, true);

        dataView.setUint32(0, data1, false);
        dataView.setUint16(4, data2, false);
        dataView.setUint16(6, data3, false);

        var uuid = new UUID();
        uuid.import(Array.from(new Uint8Array(dataCopy)));
        return uuid.toString();
    }

    //--------------------------------------------------------------------------
    resize(resolution, canvasSize)
    {
        // Do nothing if the same resolution is requested
        if(resolution.width == this.renderingAreaSize[0] && resolution.height == this.renderingAreaSize[1])
        {
            return;
        }

        this.renderingAreaSize  = [resolution.width, resolution.height];
        this.canvasSize         = canvasSize;

        const RESIZE_MESSAGE_SIZE = 5;
        var buffer      = new ArrayBuffer(FTL_HEADER_SIZE + RESIZE_MESSAGE_SIZE);
        var dataview    = new DataView(buffer);
        writeMultiplexerHeader(dataview, ChannelIDs.viewer_control, RESIZE_MESSAGE_SIZE);

        dataview.setUint8(4, viewer_control_operation.resize);
        dataview.setUint16(5, resolution.width, LITTLE_ENDIAN);
        dataview.setUint16(7, resolution.height, LITTLE_ENDIAN);
        this.socket.send(buffer);
    }

    //--------------------------------------------------------------------------
    updateViewports(viewports)
    {
        const SET_VIEWPORT_HEADER_SIZE  = 2;
        const VIEWPORTS_SIZE            = 16 + RTID_SIZE;

        var payloadSize = SET_VIEWPORT_HEADER_SIZE + VIEWPORTS_SIZE * viewports.length;

        var buffer      = new ArrayBuffer(FTL_HEADER_SIZE + payloadSize);
        var dataview    = new DataView(buffer);

        var offset = 0;
        writeMultiplexerHeader(dataview, ChannelIDs.viewer_control, payloadSize);
        offset += 4;

        dataview.setUint8(offset, viewer_control_operation.set_viewports);
        offset++;
        dataview.setUint8(offset, viewports.length);
        offset++;

        for(var i in viewports)
        {
            var viewport = viewports[i];

            dataview.setFloat32(offset, viewport.leftRatio, LITTLE_ENDIAN);
            offset += 4;

            dataview.setFloat32(offset, viewport.topRatio, LITTLE_ENDIAN);
            offset += 4;

            dataview.setFloat32(offset, viewport.widthRatio, LITTLE_ENDIAN);
            offset += 4;

            dataview.setFloat32(offset, viewport.heightRatio, LITTLE_ENDIAN);
            offset += 4;

            SDK3DVerse_Utils.writeRTID(
                viewport.cameraEntity.UNSAFE_getComponent("euid").rtid,
                dataview,
                offset
            );
            offset += RTID_SIZE;
        }

        this.viewports = viewports;
        this.socket.send(buffer);
    }

    //--------------------------------------------------------------------------
    sendEncoderParams(encoderParams)
    {
        this.sendJsonToViewerControl(viewer_control_operation.encoder_params, JSON.stringify(encoderParams));
    }

    //--------------------------------------------------------------------------
    sendJsonToViewerControl(operationID, jsonString)
    {
        const REGULAR_VIEWER_CONTROL_HEADER_SIZE = 1;

        var payloadSize     = REGULAR_VIEWER_CONTROL_HEADER_SIZE + jsonString.length;
        var buffer          = new ArrayBuffer(FTL_HEADER_SIZE + payloadSize);
        var dataView        = new DataView(buffer);
        var offset          = 0;

        writeMultiplexerHeader(dataView, ChannelIDs.viewer_control, payloadSize);
        offset += 4;

        dataView.setUint8(offset++, operationID);

        for (var i = 0; i < jsonString.length; i++)
        {
            dataView.setUint8(offset++, jsonString.charCodeAt(i));
        }

        this.socket.send(buffer);
    }

    //--------------------------------------------------------------------------
    setState(isActive)
    {
        const STATE_MESSAGE_SIZE = 1;

        var buffer      = new ArrayBuffer(FTL_HEADER_SIZE + STATE_MESSAGE_SIZE);
        var dataview    = new DataView(buffer);

        writeMultiplexerHeader(dataview, ChannelIDs.viewer_control, STATE_MESSAGE_SIZE);
        dataview.setUint8(4, isActive ? viewer_control_operation.resume : viewer_control_operation.suspend);

        this.socket.send(buffer);
    }

    //--------------------------------------------------------------------------
    setupHeartbeat()
    {
        this.heartbeatTimeout = setTimeout(this.pulseHeartbeat, HEARTBEAT_FREQUENCY);
    }

    //--------------------------------------------------------------------------
    pulseHeartbeat = () =>
    {
        const buffer    = new ArrayBuffer(FTL_HEADER_SIZE);
        const dataview  = new DataView(buffer);

        writeMultiplexerHeader(dataview, ChannelIDs.heartbeat, 0);
        this.socket.send(buffer);

        this.pingTime   = Date.now();
    }

    //--------------------------------------------------------------------------
    handleHeartbeatResponse()
    {
        if(!this.pingTime)
        {
            console.warn('Received a pong without asking for it.')
            return;
        }

        const receivedTime  = Date.now();
        const ping          = receivedTime - this.pingTime;
        this.notifier.emit('onPingResponseReceived', ping);
        this.pingTime = 0;

        this.heartbeatTimeout = setTimeout(this.pulseHeartbeat, HEARTBEAT_FREQUENCY);
    }

    //--------------------------------------------------------------------------
    async handleScriptEvents(data)
    {
        const scriptEvent = this.parseScriptEvents(data);
        this.notifier.emit('scriptEvent', scriptEvent);
    }

    //--------------------------------------------------------------------------
    parseScriptEvents(data)
    {
        const dataView      = new DataView(data);
        let offset          = 0;

        const emitterRTID   = SDK3DVerse_Utils.readRTID(dataView, offset).toString();
        offset              += RTID_SIZE;

        const eventNameSize = dataView.getUint16(offset, LITTLE_ENDIAN);
        offset              += 2;

        const eventName     = ab2str(dataView, offset, offset + eventNameSize);
        offset              += eventNameSize;

        const entityCount   = dataView.getUint16(offset, LITTLE_ENDIAN);
        offset              += 2;

        const entityRTIDs   = [...Array(entityCount)].map((_, index) =>
        {
            return SDK3DVerse_Utils.readRTID(dataView, offset + (index * RTID_SIZE)).toString();
        });

        offset              += RTID_SIZE * entityCount;

        const jsonSize      = dataView.getUint16(offset, LITTLE_ENDIAN);
        offset              += 2;

        const json          = ab2str(dataView, offset, offset + jsonSize);
        offset              += jsonSize;

        const dataObject    = json !== 'null'
                            ? JSON.parse(json)
                            : {};

        return {emitterRTID, eventName, entityRTIDs, dataObject};
    }

    //--------------------------------------------------------------------------
    handleAssetLoadingData(binaryData)
    {
        const data = JSON.parse(SDK3DVerse_Utils.arrayBufferToString(binaryData));
        this.notifier.emit('onAssetLoadingData', data);

        const newState =
               data.loading_payloads === false
            && data.pending_requests === 0
            && data.pending_scenes === 0;

        if(this.areAssetsLoaded !== newState)
        {
            this.areAssetsLoaded = newState;
            this.notifier.emit('onAssetsLoadedChanged', {areAssetsLoaded: this.areAssetsLoaded});
        }
    }

    //--------------------------------------------------------------------------
    handleGpuProfilerData(binaryData)
    {
        const data = JSON.parse(SDK3DVerse_Utils.arrayBufferToString(binaryData));
        this.gpuProfileDataQueue.push(data);

        const hasListeners = this.notifier.listenerCount('onGpuProfileData') > 0;
        if(!hasListeners)
        {
            return;
        }

        while(this.gpuProfileDataQueue.length !== 0)
        {
            this.notifier.emit('onGpuProfileData', this.gpuProfileDataQueue.shift());
        }
    }

    //--------------------------------------------------------------------------
    handleEditorMessage(binaryData)
    {
        const dataView = new DataView(binaryData);
        let offset = 0;

        const requestID = dataView.getUint32(offset, LITTLE_ENDIAN);
        offset += 4;

        const messageSize = dataView.getUint32(offset, LITTLE_ENDIAN);
        offset += 4;

        const sliceMark = dataView.getUint8(offset);
        offset += 1;

        const clientCount = dataView.getUint8(offset);
        offset += 1;

        // Skip the client UUIDs
        offset += UUID_SIZE * clientCount;

        const json = ab2str(dataView, offset, binaryData.byteLength);

        switch(sliceMark)
        {
            case NO_SLICE:
                this.notifier.emit('onEditorMessageReceived', JSON.parse(json));
                break;

            case SLICE_BEGIN:
                this.slices.set(requestID, json);
                break;

            case SLICE_CONTINUE:
            {
                const previousMessage = this.slices.get(requestID);
                if(!previousMessage)
                {
                    console.error('Received a slice continuation without a previous slice begin for request ID', requestID);
                    return;
                }
                this.slices.set(requestID, previousMessage + json);
            }
            break;

            case SLICE_END:
            {
                const previousMessage = this.slices.get(requestID);
                if(!previousMessage)
                {
                    console.error('Received a slice end without a previous slice begin for request ID', requestID);
                    return;
                }
                this.slices.delete(requestID);
                const fullMessage = previousMessage + json;
                this.notifier.emit('onEditorMessageReceived', JSON.parse(fullMessage));
            }
            break;
        }
    }

    //-------------------------------------------------------------------------
    sendEditorMessage(payload)
    {
        const messageSize   = UUID_SIZE + 4 + 4 + payload.length;

        const buffer        = new ArrayBuffer(FTL_HEADER_SIZE + messageSize);
        const bufferWriter  = new DataView(buffer);

        let offset = 0;
        writeMultiplexerHeader(bufferWriter, ChannelIDs.editor_backend, messageSize);
        offset += FTL_HEADER_SIZE;

        SDK3DVerse_Utils.serializeUUID(this.clientUUID, bufferWriter, offset);
        offset += UUID_SIZE;

        bufferWriter.setUint32(offset, 0, LITTLE_ENDIAN);
        offset += 4;

        bufferWriter.setUint32(offset, payload.length, LITTLE_ENDIAN);
        offset += 4;

        const textEncoder = new TextEncoder();
        textEncoder.encodeInto(payload, new Uint8Array(buffer, offset, payload.length));

        this.socket.send(buffer);
    }

    //--------------------------------------------------------------------------
    handleConnectionLifecycle(data)
    {
        const dataView = new DataView(data);
        const eventId = dataView.getUint8(0);

        if(eventId === connection_lifecycle_event_ids.editor_disconnected)
        {
            this.notifier.emit('onEditorDisconnected');
        }
    }
}

//------------------------------------------------------------------------------
function writeMultiplexerHeader(dataView, channelID, size)
{
    dataView.setUint8(0,    channelID,       LITTLE_ENDIAN);
    dataView.setUint8(1,    0xFF&(size>>0),  LITTLE_ENDIAN);
    dataView.setUint8(2,    0xFF&(size>>8),  LITTLE_ENDIAN);
    dataView.setUint8(3,    0xFF&(size>>16), LITTLE_ENDIAN);
}

//------------------------------------------------------------------------------
function ab2str(dataView, start, end)
{
    let result = "";

    for(var i = start; i < end; ++i)
    {
        result += String.fromCharCode(dataView.getUint8(i));
    }

    return result;
}

/**
 * Event emitted when the assets loading state changes. The first event emitted with a true value for areAssetsLoaded
 * after a start of the session means all assets are loaded, and consequently all entities of the scene graph are loaded.
 *
 * @example
 * SDK3DVerse.notifier.on('onAssetsLoadedChanged', (areAssetsLoaded) => {
 *      console.log('Have assets finished loading?', areAssetsLoaded);
 * });
 *
 * @event onAssetsLoadedChanged
 * @property {boolean} areAssetsLoaded - True if all assets has been loaded. False if some assets are loading.
 */
