//------------------------------------------------------------------------------
import SDK3DVerse_WebAPI from './WebAPI';

//------------------------------------------------------------------------------
/**
 * @ignore
 */
export default class SDK3DVerse_WebAPI_v0 extends SDK3DVerse_WebAPI {
    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(url = 'https://api.3dverse.com/legacy') {
        super(url);
        this.defaultAvatarURL = 'images/default-avatar.jpg';

        this.profilePromise = null;
        this.userMap = new Map();
        this.clientMap = new Map();
    }

    //--------------------------------------------------------------------------
    setApiToken(token) {
        this.apiToken = token;
    }

    //--------------------------------------------------------------------------
    /**
     * Request login to the 3dverse platform.
     *
     * @param {string} user  - User name
     * @param {string} password  - User password
     *
     * @method SDK3DVerse.webAPI.v0#login
     * @async
     */
    async login(user, password) {
        const result = await this.httpPost('authentication/login', {
            username: user,
            password: password,
        });

        this.apiToken = result.token;
        this.userUUID = result.userUUID;
    }

    //--------------------------------------------------------------------------
    /**
     * Create and join a new session from the 3dverse platform using
     * username and password.
     *
     * Should only be used for testing purposes.
     * Consider using [createSession]{@link SDK3DVerse.webAPI#createSession}
     * for production use.
     *
     * @param {string} user  - User name
     * @param {string} password  - User password
     * @param {string} sceneUUID  - Scene unique identifier
     *
     * @returns {object} connectionInfo - Connection information required by [startStreamer]{@link SDK3DVerse#startStreamer}
     *
     * @method SDK3DVerse.webAPI.v0#startSession
     * @async
     */
    async startSession(user, password, sceneUUID) {
        await this.login(user, password);
        return await this.createSession(sceneUUID);
    }

    //--------------------------------------------------------------------------
    async createSession(sceneUUID) {
        const creationInfo = await this.httpPost('session/create', {
            token: this.apiToken,
            sceneUUID: sceneUUID,
            options: { new_protocol: true },
        });

        return await this.joinSession(creationInfo.sessionUUID);
    }

    //--------------------------------------------------------------------------
    async joinSession(sessionUUID, route = 'session/join') {
        this.sessionUUID = sessionUUID;

        const connectionInfo = await this.httpPost(route, {
            token: this.apiToken,
            sessionUUID: this.sessionUUID,
        });

        this.setSceneId(connectionInfo.sceneUUID);

        return {
            ip: connectionInfo.endpoint.ip,
            port: connectionInfo.endpoint.port,
            sslport: connectionInfo.endpoint.sslport,
            endpoint: connectionInfo.endpoint,
            sessionKey: connectionInfo.token,
            maxUsers: connectionInfo.maxUsers,
            userUUID: connectionInfo.userUUID,
            startedAt: new Date(connectionInfo.startedAt),
        };
    }

    //--------------------------------------------------------------------------
    async createOrJoinSession(sceneUUID) {
        const workspace = await this.getAssetWorkspace('scene', sceneUUID);
        let existingSessions = await this.getSceneSessionlist(workspace.uuid, sceneUUID);
        existingSessions = existingSessions.filter(s => !s.isTransient);
        const [existingSession] = existingSessions;
        if (existingSession) {
            return this.joinSession(existingSession.uuid);
        }

        const connectionInfo = await this.createSession(sceneUUID);
        connectionInfo.sessionCreated = true;

        return connectionInfo;
    }

    //--------------------------------------------------------------------------
    // private
    async joinSessionAsWatcher(sessionUUID) {
        return this.joinSession(sessionUUID, 'session/joinAsWatcher');
    }

    //--------------------------------------------------------------------------
    async joinSessionAsGuest(guestToken) {
        let data = {
            guestToken: guestToken,
        };

        if (this.apiToken) {
            // The guest is a signed-in user
            data.token = this.apiToken;
        }

        let connectionInfo = await this.httpPost('authentication/joinGuest', data);
        this.setSceneId(connectionInfo.sceneUUID || connectionInfo.decodedPayload.sceneUUID);

        return {
            ip: connectionInfo.endpoint.ip,
            port: connectionInfo.endpoint.port,
            sslport: connectionInfo.endpoint.sslport,
            endpoint: connectionInfo.endpoint,
            sessionUUID: connectionInfo.sessionUUID || connectionInfo.decodedPayload.sessionUUID,
            sessionKey: connectionInfo.token || connectionInfo.guestSessionKey,
            maxUsers: connectionInfo.maxUsers,
            userUUID: connectionInfo.userUUID,
            startedAt: new Date(connectionInfo.startedAt),
        };
    }

    //--------------------------------------------------------------------------
    // private
    async getUserProfile() {
        if (!this.profilePromise) {
            this.profilePromise = this.httpGet(`user/profile`, {
                token: this.apiToken,
            });
        }
        return this.profilePromise;
    }

    //--------------------------------------------------------------------------
    // private
    // @async
    getUsers(uuids) {
        return this.httpPost('user/getUsers', {
            uuids: uuids,
            token: this.apiToken,
        });
    }

    //--------------------------------------------------------------------------
    // private
    async resolveUsers(users) {
        const missingUsers = users.filter(user => !this.userMap.has(user.uuid));

        if (missingUsers.length > 0) {
            const missingUsernames = missingUsers.filter(user => !user.username).map(user => user.uuid);
            const resolvedUsernames = missingUsernames.length > 0 ? await this.getUsers(missingUsernames) : {};

            for (const missingUser of missingUsers) {
                const user = {
                    uuid: missingUser.uuid,
                    username: missingUser.username || resolvedUsernames[missingUser.uuid].username,
                };

                user.image = await this.loadAvatar(user);

                this.userMap.set(missingUser.uuid, user);
            }
        }

        for (const user of users) {
            if (user.hasOwnProperty('clientUUID')) {
                this.clientMap.set(user.clientUUID, this.userMap.get(user.uuid));
            }
        }

        return users.map(user => this.userMap.get(user.uuid));
    }

    //--------------------------------------------------------------------------
    // private
    async loadAvatar(user) {
        const url = this.getURL(`user/avatar?userUUID=${user.uuid}&token=${this.apiToken}`);
        const base64 = await this.toDataURL(url);

        if (base64 != null) {
            return base64;
        }

        if (!this.defaultAvatar) {
            this.defaultAvatar = await this.toDataURL(this.defaultAvatarURL);
        }

        return this.defaultAvatar;
    }

    //--------------------------------------------------------------------------
    // private
    fetchSceneAABB(sceneUUID) {
        return this.httpGet(`scene/getAABB`, { sceneUUID, token: this.apiToken });
    }

    //------------------------------------------------------------------------------
    // private
    getAssetWorkspace(assetType, assetUUID) {
        return this.httpGet(`asset/workspace/${assetType}/${assetUUID}`, {
            token: this.apiToken,
        });
    }

    //------------------------------------------------------------------------------
    parseApiResponse(res, responseBody) {
        if (res.status >= 200 && res.status < 400) {
            return responseBody;
        }

        if (!responseBody.error) {
            throw new Error(`API Error ${res.status} : ${responseBody}`);
        }

        let error = responseBody.error;
        while (error.error) error = error.error;
        throw new Error(`API Error ${error.errorNum} : ${error.message}`);
    }

    //------------------------------------------------------------------------------
    // private
    async getSceneSessionlist(workspaceUUID, sceneUUID) {
        const sceneSessions = await this.httpGet('workspace/listSessions', {
            workspaceUUID,
            token: this.apiToken,
        });

        if (!sceneSessions.hasOwnProperty(sceneUUID)) {
            return [];
        }

        const sessionUUIDs = Object.keys(sceneSessions[sceneUUID].sessions);
        let sessions = [];
        for (const uuid of sessionUUIDs) {
            const session = sceneSessions[sceneUUID].sessions[uuid];
            sessions.push({ uuid, ...session });
        }

        return sessions.sort((a, b) => {
            return a.startedAt > b.startedAt ? 1 : a.startedAt < b.startedAt ? -1 : 0;
        });
    }

    //--------------------------------------------------------------------------
    toDataURL(url) {
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest();
            xhr.onloadend = function () {
                if (xhr.status == 200) {
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                } else {
                    xhr.onerror = null;
                    resolve(null);
                }
            };
            xhr.onerror = function () {
                xhr.onloadend = null;
                resolve(null);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }
}
